<template>
  <div>
    <div v-if="isBusy">
      <div class="d-flex align-items-center min-vh-100">
        <CContainer fluid>
          <CRow class="justify-content-center">
            <CCol md="6">
              <div class="md-title text-center mb-3">Creating account</div>
              <md-progress-bar md-mode="indeterminate"></md-progress-bar>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    </div>
    <div v-else class="d-flex align-items-center min-vh-100">
      <CContainer fluid>
        <CRow class="justify-content-center">
          <CCol md="6">
            <md-card>
                  <form action="#" @submit.prevent="registerButtonPressed">
                    <md-card-header>
                        <h1 class="md-title">Create account</h1>
                       

                    </md-card-header>

                    <md-card-content>
                        <CRow>
                            <CCol sm="12">
                               
                                <md-field :class="getValidationClass('email')">
                                    <label>email</label>
                                    <md-input v-model="email"></md-input>
                                    <span class="md-error" v-if="!$v.email.required">email is required</span>
                                    <span class="md-error" v-if="!$v.email.email">Invalid email address</span>
                                </md-field>

                                <md-field :class="getValidationClass('password')">
                                    <label>password</label>
                                    <md-input v-model="password" type="password"></md-input>
                                    <span class="md-error" v-if="!$v.password.required">email is required</span>
                                </md-field>
                                <md-field :class="getValidationClass('role')">
                                    <label for="role">Role</label>
                                    <md-select v-model="role" name="role" id="role" @md-selected="roleSelected()">
                                        <md-option v-for="role in roles" :value="role.name" v-bind:key="role.name">{{ role.text }}</md-option>
                                        
                                    </md-select>
                                    <span class="md-error" v-if="!$v.role.required">Role is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('subrole')" v-if="role === 'driver'">
                                    <label for="subrole">Driver type</label>
                                    <md-select v-model="subrole" name="subrole" id="subrole">
                                        <md-option  :value="'owner-operator'">Owner operator (I have my own car)</md-option>
                                        <md-option  :value="'company'">Company driver (I drive a vehicle from company fleet)</md-option>
                                        
                                    </md-select>
                                    <span class="md-error" v-if="!$v.subrole.required">Subrole is required</span>
                                </md-field>
                                <p>Already have an account ? <a @click="goToLogin()">Login</a></p>
                            </CCol>
                            
                        </CRow>
                        <CRow >
                            <CCol sm="12">
                                <md-chip class="mb-2 uplimo-green full-width-button text-center big-text up-text-soft-black" v-if="role && role != 'company-admin'">Free suscription
                                </md-chip>
                                <md-chip class="mb-2 uplimo-green full-width-button text-center big-text up-text-soft-black" v-if="role == 'company-admin'">Free trial available! See pricing.
                                </md-chip>
                                <md-button class="md-raised full-width-button md-primary" type="submit">Register</md-button>
                                <md-field class="md-invalid" v-if="error">
                                  <span class="md-error">{{ error }}</span>
                                </md-field>
                            </CCol>
                        </CRow>
                    </md-card-content>
                  </form>
              
              </md-card>
            
          </CCol>
        </CRow>
      </CContainer>
    </div>
  </div>
 
</template>

<script>
import axios from "./../../helpers/axios"
import { validationMixin } from 'vuelidate'
import {
    required,
    between,
    email,
    minLength,
    maxLength,
    requiredIf
} from 'vuelidate/lib/validators'
import firebase from '../../Firebase'
export default {
  name: 'Register',
  mixins: [validationMixin],
  data() {
    return {
      error: null,
      isBusy:false,
      email: '',
      password: '',
      role: '',
      subrole: null,
      roles: [
          
          {
              name: 'company-admin',
              text: 'Car and limo service company'
          },
          {
              name: 'driver',
              text: 'Driver'
          },
          {
              name: 'dispatcher',
              text: 'Dispatcher'
              // TODO: Agregar subrole Company or Freelance
          },
          {
              name: 'affiliate',
              text: 'Affiliate'
              // receives and executes jobs with very resumed version of reservations module
          },
          {
              name: 'client',
              text: 'Corporate client'
              // receives and executes jobs with very resumed version of reservations module
          },
          // {
          //     name: 'provider',
          //     text: 'Provider'
          // },
          
      ]
    }
  },
  methods: {
    roleSelected() {
      
      if(this.role != 'driver') {
        this.subrole = null
      }
    },
    validate(){
        let email,password,role,subrole;
        email = this.$v.email.$invalid;
        password = this.$v.password.$invalid;
        role = this.$v.role.$invalid;
        subrole = this.$v.subrole.$invalid;
        
        return !email && !password && !role && !subrole ? true : false ;


    },
    async createUser() {
      
      let th = this;
      let displayName = this.email
      let data = {
          password: this.password, 
          email: this.email, 
          ownerEmail: null,
          role: this.role,
          subrole: this.subrole,
          displayName
      }

      return new Promise((resolve, reject) => {
            axios.post('users',data).then(response =>{
                console.log(response);
                th.sending = false
                th.isBusy = false
                
                resolve(response)
                //th.$router.push({path: '/users/drivers'})
            }).catch(e => {
                console.log(e);
                
                th.sending = false
                th.isBusy = false
                reject(e)
                
            }) 
      });
      
      
    },

    async registerButtonPressed() {
      try {
        
        if(this.validate()) {
          this.isBusy = true;
          const user = await this.createUser()
          console.log(user)

          if(user.data.uid) {
            this.$router.push({
              name: 'Login',
              params: { message: 'Your account has been successfully created. Please login with selected credentials', messageType: 'success' }
            })
          } else {
            this.error = user.data.error.message
          }
          
          this.isBusy = false;
        } else {
          this.$v.$touch()
        }
        // var {
        //   user
        // } = await this.$firebase
        //   .auth()
        //   .createUserWithEmailAndPassword(this.email, this.password)

        // await firebase
        //     .firestore()
        //     .collection('users')
        //     .doc(user.uid).set({
        //         photo: null
        //     })

        // //console.log(collection);
        // // signout
        // this.$firebase
        //   .auth()
        //   .signOut()
        //   .then(user => {
        //     //console.log(user);
        //     this.$router.push("/pages/login");
        //   });
      } catch (error) {
        this.error = error;
        // console.log(error.message);
      }
      
    },
    goToLogin(){
      this.$router.push({
        path: `login`
        
      })


    },
    getValidationClass (fieldName) {
        let field = null;

        
        field = this.$v[fieldName];
        
        
        
        if (field) {
            return {
                'md-invalid': field.$invalid && field.$dirty
            }
        }
    },

  },
  validations: {
       
       
           
    email: {
        required,
        email
    },
    password: {
        required,
        minLength: minLength(6)
    },
    role: {
      required
    },
    subrole: {
      required: requiredIf(function(){
        console.log(this.role)
        return this.role === 'driver' ? true : false;
      }),
    }
           
       
   }
}
</script>
